import { ref, inject } from "vue";
import api from "@/services/userApi";

export default function useNumbers() {
  const t = inject("t");
  const number = ref({
    description: "",
    country: "",
    type: "2",
  });
  const numbers = ref([]);
  const notify = inject("notify");
  const n = (label, type = "danger") => {
    notify({
      message: t(`${label}`),
      type,
    });
  };

  //   FIND NUMBERS
  const findNumbers = async () => {
    try {
      const response = await api.get("/admin/xpbx/number");

      if (response?.data) numbers.value = response.data;
    } catch (error) {
      console.log(error);
    }
  };

  //   FIND NUMBER
  const findNumber = async (id) => {
    try {
      const response = await api.get(`/admin/xpbx/number/${id}`);

      if (response?.data?.length) {
        number.value = response.data[0];
      }
    } catch (error) {
      console.log(error);
    }
  };

  //   CREATE NUMBER
  const createNumber = async (data) => {
    try {
      const response = await api.post(`/admin/xpbx/number`, data);

      if (response?.data?.id) {
        numbers.value.push(response.data);
        n("notify.xpbx.create_number", "success");
      } else {
        n("notify.xpbx.error_create_number");
      }
    } catch (error) {
      n("notify.xpbx.error_create_number");
    }
  };

  //   UPDATE NUMBER
  const updateNumber = async (data, id) => {
    try {
      const response = await api.put(`/admin/xpbx/number/${id}`, data);

      if (response?.data?.id) {
        const index = numbers.value.findIndex((i) => i.id === id);
        numbers.value[index] = response.data;
        n("notify.xpbx.edit_number", "success");
      } else {
        n("notify.xpbx.error_edit_number");
      }
    } catch (error) {
      n("notify.xpbx.error_edit_number");
    }
  };

  //   DELETE NUMBER
  const deleteNumber = async (id) => {
    try {
      const response = await api.delete(`/admin/xpbx/number/${id}`);

      if (response.status === 200) {
        n("notify.xpbx.delete_number", "success");

        numbers.value = numbers.value.filter((i) => i.id !== id);
      } else {
        n("notify.xpbx.error_delete_number");
      }
      return response;
    } catch (error) {
      n("notify.xpbx.error_delete_number");
      return error;
    }
  };

  //   DELETE NUMBERS
  const deleteNumbers = async (ids) => {
    for (const item of ids) {
      try {
        const response = await api.delete(`/admin/xpbx/number/${item?.id}`);

        if (response.status === 200) {
          numbers.value = numbers.value.filter((i) => i.id !== item?.id);
        } else {
          n("notify.xpbx.error_delete_number");
        }
        return response;
      } catch (error) {
        n("notify.xpbx.error_delete_number");
        return error;
      }
    }

    n("notify.xpbx.delete_number", "success");
  };

  return {
    number,
    numbers,
    findNumber,
    findNumbers,
    createNumber,
    updateNumber,
    deleteNumber,
    deleteNumbers,
  };
}
