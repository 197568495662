<template>
  <div class="bg-white w-full h-full">
    <section class="w-full h-full">
      <PageHeader
        :heading="$t('xpbx.pages.heading.numbers')"
        :showButton="false"
      />

      <!-- DId numbers table -->
      <div class="card relative table-wrapper">
        <DataTable
          ref="dt"
          :value="numbers"
          scrollable
          :scrollHeight="`${scrollHeight}px`"
          tableStyle="min-width: 50rem"
          v-if="numbers && numbers?.length"
          :paginator="true"
          :rows="10"
          :filters="filters"
          paginatorPosition="top"
          v-model:selection="selectedRecords"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rowsPerPageOptions="[5, 10, 25]"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} dids"
          selectionMode="single"
          dataKey="id"
          removableSort
          sortMode="multiple"
          v-model:filters="filters"
          filterDisplay="menu"
          :rowClass="rowClass"
          @rowSelect="onRowSelect"
          @rowUnselect="onRowUnselect"
          :globalFilterFields="['description']"
        >
          <template #header>
            <div
              class="flex flex-wrap gap-2 align-items-center justify-content-between w-full"
            >
              <div>
                <Button
                  :label="$t('xpbx.button.new')"
                  icon="pi pi-plus"
                  severity="success"
                  class="mr-2 add-record-button"
                  @click="openNew"
                />
                <Button
                  :label="$t('xpbx.button.delete')"
                  icon="pi pi-trash"
                  severity="danger"
                  class="delete-button"
                  @click="confirmDeleteSelected"
                  :disabled="!selectedRecord?.id"
                />
              </div>

              <div class="flex gap-2 items-center ml-auto">
                <IconField iconPosition="left">
                  <InputIcon>
                    <i class="pi pi-search" />
                  </InputIcon>
                  <InputText
                    v-model="filters['global'].value"
                    :placeholder="$t('xpbx.placeholders.search')"
                  />
                </IconField>
                <Export
                  :dt="dt"
                  :tableData="mappedDidNumbers"
                  :columns="didsTable"
                />
              </div>
            </div>
          </template>
          <!-- <Column
            selectionMode="multiple"
            style="width: 3rem"
            :exportable="false"
          ></Column>
          <Column
            field="status"
            sortable
            :header="$t('xpbx.table-field.status')"
          >
            <template #body="slotProps">
              <StatusIcon :status="+slotProps?.data?.is_active" />
            </template>
          </Column> -->
          <Column
            sortable
            field="number"
            style="width: 15%"
            :header="$t('xpbx.table-field.phone_number')"
          />
          <Column
            sortable
            field="description"
            style="width: 30%"
            :header="$t('xpbx.table-field.description')"
          />
          <Column
            sortable
            field="country"
            style="width: 10%"
            :header="$t('xpbx.table-field.country')"
          >
            <template #body="slotProps">
              {{ getCountryName(slotProps?.data?.country_id) }}
            </template>
          </Column>
          <Column
            sortable
            field="number_type_id"
            style="width: 15%"
            :header="$t('xpbx.table-field.number_type')"
          >
            <template #body="slotProps">
              {{ getNumberType(slotProps?.data?.number_type_id) }}
            </template>
          </Column>
          <Column
            sortable
            field="created_at"
            style="width: 10%"
            :header="$t('xpbx.table-field.created_at')"
          >
            <template #body="slotProps">
              <DateItem :value="slotProps?.data?.created_at" />
            </template>
          </Column>
          <Column
            field="updated_at"
            style="width: 10%"
            :header="$t('xpbx.table-field.updated_at')"
          >
            <template #body="slotProps">
              <DateItem :value="slotProps?.data?.updated_at" /> </template
          ></Column>
        </DataTable>
        <Loader v-else />
      </div>

      <!-- Dialogs -->
      <Dialog
        v-model:visible="deleteRecordsDialog"
        :style="{ width: '450px' }"
        header="Confirm"
        :modal="true"
        class="p-fluid relative"
      >
        <div class="confirmation-content">
          <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
          <span class="text-center">{{
            $t("notify.xpbx.message.confirm_delete", {
              delete: `number ${
                selectedRecord?.number ? selectedRecord.number : ""
              }`,
            })
          }}</span>
        </div>
        <template #footer>
          <Button
            label="No"
            icon="pi pi-times"
            text
            @click="deleteRecordsDialog = false"
          />
          <Button
            label="Yes"
            icon="pi pi-check"
            text
            @click="deleteSelectedRecords"
          />
        </template>
      </Dialog>

      <!-- Create dids -->
      <Dialog
        v-model:visible="numberDialog"
        :style="{ width: '450px' }"
        :header="$t('xpbx.pages.heading.create_number')"
        :modal="true"
        :dismissableMask="true"
        class="p-fluid relative custom-dialog-heading"
      >
        <div class="field mb-1 select-autocomplete">
          <label for="description mb-2">{{
            $t("label.profile.profileinfo-pwd.body.company-info.country")
          }}</label>
          <SelectWithSearch
            v-model="number.country"
            :options="countryOptions"
            :bordered="true"
            name="country"
            id="country"
            :errors="errors.country"
            :footerLabel="
              $t('label.profile.profileinfo-pwd.body.company-info.country')
            "
          />
        </div>
        <QueueHint :title="$t('xpbx.hints.internal_numbers.country')" />

        <!-- Title -->
        <div class="field mb-1 mt-4 relative">
          <label for="channel">{{ $t("xpbx.labels.number_types") }}</label>
          <form-select
            v-model="number.type"
            :options="types"
            name="channel"
            id="channel"
            :errors="errors.type"
            :activeClass="true"
          />
        </div>
        <QueueHint :title="$t('xpbx.hints.internal_numbers.type')" />

        <template #footer>
          <Button
            label="Cancel"
            icon="pi pi-times"
            text
            @click="numberDialog = false"
          />
          <Button label="Save" icon="pi pi-check" text @click="create" />
        </template>
      </Dialog>

      <!-- Edit dids -->
      <Dialog
        v-model:visible="numberEditDialog"
        :style="{ width: '450px' }"
        :header="$t('xpbx.pages.heading.edit_number')"
        :modal="true"
        :dismissableMask="true"
        class="p-fluid relative custom-dialog-heading"
      >
        <div class="field mb-1">
          <label for="description">{{ $t("xpbx.labels.description") }}</label>
          <InputText
            id="description"
            type="text"
            v-model.trim="number.description"
            required="true"
            autofocus
            :class="{ 'p-invalid': submitted && !number.description }"
          />
          <small
            class="p-error"
            v-if="submitted && !!errors?.description?.[0]"
            >{{ errors?.description?.[0] }}</small
          >
        </div>
        <QueueHint :title="$t('xpbx.hints.internal_numbers.description')" />

        <template #footer>
          <Button
            label="Cancel"
            icon="pi pi-times"
            text
            @click="numberEditDialog = false"
          />
          <Button label="Save" icon="pi pi-check" text @click="edit" />
        </template>
      </Dialog>
    </section>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { ref, onMounted, computed } from "vue";
import PageHeader from "@/modules/xpbx/components/home/UI/PageHeader.vue";
import useNumbers from "@/modules/xpbx/composables/useNumbers";
import StatusIcon from "@/modules/xpbx/components/UI/status-icon/index.vue";
import Loader from "@/modules/xpbx/components/UI/loader/index.vue";
import DateItem from "@/modules/xpbx/components/UI/date-item/index.vue";
import { validateCreateNumber } from "@/composables/auth/createNumberValidations";
// Datatable
import Column from "primevue/column";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import Dialog from "primevue/dialog";
import DataTable from "primevue/datatable";
import IconField from "primevue/iconfield";
import InputIcon from "primevue/inputicon";
import { FilterMatchMode } from "primevue/api";
import validator from "@/composables/auth/validator";
import Export from "@/modules/xpbx/pages/settings/components/export/index.vue";
import { didsTable } from "@/modules/xpbx/pages/settings/components/table-headers/data.js";
import QueueHint from "@/modules/xpbx/pages/settings/queue-detail/components/queue-hint/index.vue";

export default {
  name: "InternalNumbersTable",
  components: {
    PageHeader,
    StatusIcon,
    Loader,
    DateItem,
    DataTable,
    Column,
    Button,
    InputText,
    Dialog,
    IconField,
    InputIcon,
    Export,
    QueueHint,
  },

  setup() {
    const store = useStore();
    const {
      findNumbers,
      deleteNumber,
      createNumber,
      updateNumber,
      numbers,
      number,
    } = useNumbers();
    const countries = computed(() => store.getters.countries);

    const countryOptions = computed(() => {
      return countries.value.map((country) => {
        return {
          name: country.country_name,
          value: country.id,
        };
      });
    });

    // Datatable
    const dt = ref();
    const isEdit = ref(false);
    const submitted = ref(false);
    const { errors } = validator();
    const scrollHeight = ref(window.innerHeight - 290);
    const numberDialog = ref(false);
    const numberEditDialog = ref(false);

    const selectedRecord = ref(null);
    const selectedRecords = ref([]);
    const deleteRecordsDialog = ref(false);
    const filters = ref({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    const mappedDidNumbers = computed(() => {
      return numbers.value.map((item) => {
        const country = countries.value.find(
          (country) => country.id === item.country_id
        );
        return {
          ...item,
          country: country?.country_name || "",
          numberType: getNumberType(item.number_type_id) || "Home",
        };
      });
    });

    const getCountryName = (countriID) => {
      if (!countries.value) return "";
      const country = countries.value.find(
        (country) => country.id === countriID
      );
      return country?.country_name;
    };

    const getNumberType = (type) => {
      switch (type) {
        case 1:
          return "Home";
        case 2:
          return "Mobile";
        case 3:
          return "Office";
        default:
          return "Local";
      }
    };

    // Data table functions
    const openNew = () => {
      numberDialog.value = true;
    };

    const closeDialog = (value) => {
      numberDialog.value = value;
    };

    const create = async () => {
      const isValid = validateCreateNumber(number.value);

      if (isValid) {
        const country = countries.value.find(
          (x) => x.country_name === number.value.country
        );

        const countryID = country.id;

        const formData = {
          number_type_id: +number.value.type,
          country_id: `${countryID}`,
        };

        await createNumber(formData);

        numberDialog.value = false;
      }
    };

    const edit = async () => {
      await updateNumber(number.value, number.value.id);
      numberEditDialog.value = false;
    };

    const confirmDeleteProduct = async (data) => {
      number.value = data;
    };

    const confirmDeleteSelected = () => {
      deleteRecordsDialog.value = true;
    };

    const deleteSelectedRecords = async () => {
      await deleteNumber(selectedRecord.value.id);
      deleteRecordsDialog.value = false;
    };

    const onRowSelect = (event) => {
      isEdit.value = false;
      selectedRecord.value = event.data;
    };

    const onRowUnselect = (event) => {
      if (selectedRecord.value?.id === event.data.id) {
        number.value = event.data;
        isEdit.value = true;
        numberEditDialog.value = true;
      }
    };

    const rowClass = (data) => {
      return [
        {
          "table-active": data.is_active === 1,
          "table-inactive": data.is_active === 0,
          "table-selected": isEdit.value && number?.value?.id === data?.id,
        },
      ];
    };

    const types = [
      { value: 1, name: "Mobile" },
      { value: 2, name: "Home" },
      { value: 3, name: "Office" },
    ];

    const checkTableClick = () => {
      const appContainer = document.querySelector(".app-container");

      appContainer.addEventListener("click", (e) => {
        const classLength = e?.target?.classList?.length;
        const includeButton = e?.target?.classList?.contains("p-button-label");
        if (classLength > 0 && selectedRecord?.value?.id && !includeButton)
          selectedRecord.value = null;
      });
    };

    onMounted(async () => {
      checkTableClick();
      await findNumbers();
    });

    return {
      dt,
      types,
      filters,
      isEdit,
      didsTable,
      selectedRecord,
      selectedRecords,
      deleteRecordsDialog,
      numberDialog,
      numberEditDialog,
      mappedDidNumbers,
      submitted,
      errors,
      rowClass,
      number,
      numbers,
      scrollHeight,
      countryOptions,
      getNumberType,
      getCountryName,
      openNew,
      closeDialog,
      edit,
      create,
      confirmDeleteProduct,
      onRowSelect,
      onRowUnselect,
      confirmDeleteSelected,
      deleteSelectedRecords,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/data-table.scss";

.select-autocomplete .form-control {
  border: 1px solid #94a3b8;
}
</style>