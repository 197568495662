<template>
  <div class="bg-white w-full p-3">
    <InternalNumbersTable />
  </div>
</template>

<script>
import InternalNumbersTable from "@/modules/xpbx/components/internal-numbers/internal-numbers";

export default {
  name: "DidNumbers",

  components: {
    InternalNumbersTable,
  },
  created() {
    this.$i18n.locale = localStorage.getItem("user_lang");
  },
};
</script>