import { computed, unref } from "vue";
import validator from "@/composables/auth/validator";

const hasErrors = computed(() => Object.entries(errors.value).length);
const {
  checkRequired,
  checkValueNotIn,
  checkEmail,
  checkIsNumber,
  errors,
  clearErrors,
  isValidUrl,
} = validator();

export function validateCreateNumber(data) {
  clearErrors();

  data = unref(data);

  checkRequired("country", data);
  checkRequired("type", data);

  return hasErrors.value ? false : true;
}

export function validateCreateExtensionNumber(data) {
  clearErrors();

  data = unref(data);

  checkRequired("description", data);
  checkRequired("extension", data);
  checkRequired("is_active", data);

  return hasErrors.value ? false : true;
}

export function validateUpdateNumber(data) {
  clearErrors();

  data = unref(data);

  checkRequired("description", data);

  return hasErrors.value ? false : true;
}
