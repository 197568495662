<template>
  <div class="flex justify-start w-8">
    <i class="fa-solid fa-check text-lg" v-if="status === 1"></i>
    <i class="fa-solid fa-xmark text-lg" v-else></i>
  </div>
</template>

<script>
export default {
  name: "StatusIcon",

  props: {
    status: {
      type: Number,
      default: 1,
      required: true,
    },
  },
};
</script>

<style lang="scss">
.fa-check {
  color: #1ab394;
}
.checked-icon {
  fill: #1ab394;
  margin: 0 auto;
}

.fa-xmark {
  color: crimson;
}
.unchecked-icon {
  fill: crimson;
}
</style>